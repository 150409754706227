import container from "@core/di";
import { NotificationType, showNotification } from "@shared/components/Notification";
import { MAP_CONSTRUCTION_PATHS, MAP_EMI_PATHS, MAP_GENERAL_WARNING_PATHS, MAP_HIGH_ALTITUDE_PATHS, MAP_ICON_OFFSET_STRING, MAP_MULTIPLE_TOWERS_PATHS, MAP_PATHS, MAP_ROAD_ACCESS_ISSUES_PATHS, typeOthersPath } from "@shared/constants/gmap-data";
import { CONDITION_LABEL_MAP } from "@shared/constants/sp-sheets";
import { MapMarkerDTO } from "@shared/models/gmap-data";
import { FilterItems } from "@shared/models/jobrequesttimelines";
import Order from "@shared/models/order";
import { AssetsStore, OrdersStore } from "@shared/stores";
import { ListRequestParams } from "@shared/types/services";
import { addNewItems2Array, condStr2Num, errObj2Str, formatAddress } from "@shared/utils/common";

// Generate a list of markers for displaying on a map by reading site data for a given order Id.
// Also get a list of unique staff ids, a list of assets from the job requests in this Order (Note: uniquesStaffIds, assetList are passed by reference). 
export async function buildMarkersFromOrderID(orderID: number, 
  filterConfig: Array<FilterItems>, 
  uniqueStaffIds: Array<number> | undefined = undefined, 
  filterAssetList: Array<{id: number, name: string, tag: string}> | undefined = undefined): Promise<MapMarkerDTO[]> {
    const markers: Array<MapMarkerDTO> = [];
    const ordersStore = container.get<OrdersStore>(OrdersStore.diToken);
    const assetsStore = container.get<AssetsStore>(AssetsStore.diToken);
    try {
        console.log(`*** Start getting orderdetails @ ${(new Date()).getTime()}:`, orderID);

        // Check if the filters contain any ids or keywords.
        const filterOn = filterConfig.reduce((sum, item2) => sum || 
                                             ((item2.ids === undefined || item2.ids.length == 0) ? false : true) ||  // has Id's
                                             (item2.keywords ? true: false),                                         // has keywords
                                             false);
        let order: Order;
        if(filterOn) {  // Load order info with filters.
          const filtering = {
            byCategory: filterConfig,
          };
    
          // setup params to pass the filtering keys.
          const params: ListRequestParams = {
            pagination: {
              page: 1,
              pageSize: 1,      
            },
            filtering: filtering,
          };
  
          order = await ordersStore.getOrderFullInfoFiltered(orderID, params)
        }
        else { // Load order info without filters from portal-api. Take around < 1 sec.
          order = await ordersStore.get(orderID);
        }
        
        //console.log("*** loadMarkers order:", order);
  
        // Get info for all assets in one call for efficiency
        const assetIds = order.assets.map((a) => { return a.assetId; });
        const assets = await assetsStore.getAssetListInfo(assetIds);
        if (assets.assetList.length == 0)
        {
          throw(new Error('getAsset returned no records.'));
        }
  
        ordersStore.setAssetList(assets.assetList);

        if(filterAssetList !== undefined) {
          filterAssetList.length = 0; // clear the list for the new site.
        }

        order.assets.forEach(async (asset) => {      
          // Update asset deliverables.
          asset.deliverables.forEach((deliverable) => {
            ordersStore.setDeliverable(asset.assetId, deliverable);
          });
  
          // Update asset job requests.
          asset.jobRequests?.forEach((jobRequest) => {
            ordersStore.setJobRequest(asset.assetId, jobRequest);
          });
  
          // Locate the matching assess from the asset list.
          const matchedAsset = ordersStore.assets.find((curAsset) => curAsset.id == asset.assetId);
          let assetName = asset.name;
          if (asset.jobRequests != null && asset.jobRequests.length > 0)
          {
            const jobReq = asset.jobRequests[0];

            let pilotName = jobReq.pilotName;
            if (pilotName == undefined || pilotName.length == 0)
            {
              const pilotId = jobReq.pilotId;
              pilotName = pilotId !== undefined ? pilotId.toString() : "";
            }
            if (pilotName.length > 0)
            {
              assetName += ' (' + pilotName + ')';
            }

            if(uniqueStaffIds !== undefined) {
              addNewItems2Array(uniqueStaffIds, jobReq.pilotId, jobReq.observerId, jobReq.postProcessingTechnicianId, jobReq.productionTechnicianId);
            }
            if(filterAssetList !== undefined) {
              // Add asset to the asset list for filtering.
              filterAssetList.push({id: asset.assetId, name: asset.name, tag: (asset.jobRequests &&  asset.jobRequests.length > 0) ? asset.jobRequests[0].tag1 : ''});
            }
          }
  
          // Add new marker to list.
          markers.push({
            assetId: asset.assetId, 
            serialNumber: asset.serialNumber,
            jobRequests: (asset.jobRequests ? asset.jobRequests : []) ,
            name: assetName,
            lat: matchedAsset ? matchedAsset.coordinates.lat : 0,
            lng: matchedAsset ? matchedAsset.coordinates.lon : 0,
            address: matchedAsset && matchedAsset.address ? formatAddress(matchedAsset.address) : '',
            type: matchedAsset?.construction.type,
            lastComment: '',
            //rank: DEFAULT_RANK,
            collectionAge: 0,
            visualsAge: 0,
            processingAge: 0,
            productionAge: 0,
            LiDARAge: 0,
            externalsAge: 0,
            collectionPercent: 0,
            visualsPercent: 0,
            processingPercent: 0,
            productionPercent: 0,
            LiDARPercent: 0,
            externalsPercent: 0,
            processingCost: 0
          })
        });
    } 
    catch (err) {
        showNotification(errObj2Str(err), NotificationType.error);
    }
    return markers;
}

export function computeBoundFromMarkers(markers: Array<MapMarkerDTO>): {latMin: number, lngMin: number, latMax: number, lngMax: number} {
    // Default bound -- US map
    const bounds = {latMin: 16.639000880823524, latMax: 56.946573247221494, lngMin: -124.6973894912498, lngMax: -71.9630144912498};

    if (markers.length > 0) {
        bounds.latMin = markers.map(site => site.lat).reduce((a, b) => (a < b ? a : b));
        bounds.latMax = markers.map(site => site.lat).reduce((a, b) => (a > b ? a : b));
        bounds.lngMin = markers.map(site => site.lng).reduce((a, b) => (a < b ? a : b));
        bounds.lngMax = markers.map(site => site.lng).reduce((a, b) => (a > b ? a : b));

        // Add extra 1% to each direction
        const extraLat = (bounds.latMax - bounds.latMin) / 100;
        const extraLng = (bounds.lngMax - bounds.lngMin) / 100;
        bounds.latMin -= extraLat;
        bounds.latMax += extraLat;
        bounds.lngMin -= extraLng;
        bounds.lngMax += extraLng;
    }

    return bounds;
}

// Setup of icon for a marker.  The shape is from antenna type and fill color from status.
export function makeMarkerIcon(marker: MapMarkerDTO): {path: string, size: number} {
    // Place special conditions icons to the right of construction type icon
    let path = marker.type ? MAP_PATHS[marker.type] : typeOthersPath; // set path for antenna type.
    let size = 18;
    if (marker.jobRequests && marker.jobRequests.length > 0)
    {
        let iconIndex = 0;  // If multiple special conditions
        const jobRequest = marker.jobRequests[0];
        const specialConditions = [
            jobRequest.emi,
            jobRequest.multipleTowers,
            jobRequest.highAltitude,
            jobRequest.underConstruction,
            jobRequest.roadAccessIssues,
            condStr2Num(jobRequest.otherConditions)
        ];

        const pathArrays = [MAP_EMI_PATHS, MAP_MULTIPLE_TOWERS_PATHS, MAP_HIGH_ALTITUDE_PATHS, MAP_CONSTRUCTION_PATHS,
            MAP_ROAD_ACCESS_ISSUES_PATHS, MAP_GENERAL_WARNING_PATHS];
        for (let i = 0 ; i < specialConditions.length; ++i)
        {
            if (specialConditions[i] > 0) {
                const pathElement = pathArrays[i];
                for (let idx = 0; idx < pathArrays[i].length; ++idx)
                {
                    path = path + MAP_ICON_OFFSET_STRING[iconIndex] + pathElement[idx];
                    size += 8
                }
                iconIndex += 1;
            }
        }
    }

    // Highlight if marker is currently selected.
    //if(idx === selectedIdx) {
        //path = path + " " + MAP_PATHS[0]; 
    //}

    // return the combined paths.
    return {path, size};
}

export function makeConditionsStatus(marker: MapMarkerDTO): string {
    if (marker.jobRequests && marker.jobRequests.length > 0)
    {
        const jobRequest = marker.jobRequests[0];
        const specialConditions = [
            jobRequest.emi,
            jobRequest.multipleTowers,
            jobRequest.highAltitude,
            jobRequest.underConstruction,
            jobRequest.roadAccessIssues,
            condStr2Num(jobRequest.otherConditions)
        ];

        const conditionStr = [CONDITION_LABEL_MAP.emi, CONDITION_LABEL_MAP.multipleTowers, CONDITION_LABEL_MAP.highAltitude, 
            CONDITION_LABEL_MAP.underConstruction, CONDITION_LABEL_MAP.roadAccessIssues, CONDITION_LABEL_MAP.otherConditions];
        
        let conditions: string[] = [];
        specialConditions.forEach((x, idx) => {
            if (x) {
                conditions.push(conditionStr[idx]);
            }
        })
        return conditions.length > 0 ? conditions.join(', ') : 'None';
    }
    return 'None';
}